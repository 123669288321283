.main-swiper-footer {
  height: fit-content;
}
.footer-banner-image img {
  width: 100%;
  height: 100%;
}
.swiper-pagination-bullets {
  opacity: 0;
}
