.hero-section {
  z-index: 1;
  width: 100%;
  height: 100%;
}
.swiper {
  width: 100%;
  height: 95vh;
}

.swiper-slide {
  /* text-align: center; */
  background: #fff;
  display: flex !important;
}
.swiper-slide-hero-same {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.swiper-slide-hero-1 {
  /* background-image: url("../HeroSection.js//HeroSection/Slide-1.png"); */
  /* background: linear-gradient(107deg, #00b4c6 0%, #008ae7 100%); */
  background-image: url("./HeroSection/SLIDE\ DEMO2.svg");
}

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 2rem !important;
  color: white;
}

.swiper-slide-hero-same .container {
  margin: 0;
}
.swiper-slide-hero-1-sub {
  height: 100%;
  flex-direction: column;
  align-items: baseline;
  margin-top: 4rem;
}
.swiper-slide-hero-1-sub h5 {
  text-align: left;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.4rem 0.6rem;
  background-color: rgb(255, 255, 255);
  border-radius: 20px 0 0 0;
  text-transform: uppercase;
}
.swiper-slide-hero-1-sub h2 {
  width: 45rem;
  padding: 1.5rem 0 2.5rem 0;
  color: #fff;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 50px;
  text-transform: uppercase;
  text-align: left;
}
.swiper-slide-hero-1-sub p {
  color: #fff;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
  width: 45rem;
  text-align: left;
}

.swiper-slide-hero-2 {
  width: 100%;
  height: 100%;
  background-image: url("./HeroSection/Slide-demo4.jpg");
}
.swiper-slide-hero-2-sub {
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: baseline;
}

.swiper-slide-hero-2-sub h5 {
  text-align: left;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.4rem 0.6rem;
  background-color: rgb(255, 255, 255);
  border-radius: 20px 0 0 0;
  text-transform: uppercase;
}
.swiper-slide-hero-2-sub p {
  width: 46rem;
  color: #fff;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
}
.swiper-slide-hero-2-sub h2 {
  width: 40rem;
  padding: 1.8rem 0;
  color: #fff;
  font-size: 2.25rem;
  font-weight: 700;

  line-height: 2.9375rem;
  text-transform: capitalize;
}
.swiper-slide-hero-fixed {
  position: absolute;
  top: 17px;
  left: 800px;
}
.swiper-slide-hero-3 {
  background-image: url("./HeroSection/SLIDE-DEMO3.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 0;
  /* overflow: hidden; */
}
.swiper-slide-hero-3-flex {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 2rem;
}

.swiper-slide-hero-fixed img {
  width: 30rem;
}
.swiper-slide-hero-3-flex-img img {
  width: 110%;
}
.swiper-slide-hero-3-flex-detail {
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  align-self: center;
}
.swiper-slide-hero-3-flex-detail #swiper-slide-hero-3-flex-detail-h5 {
  text-align: left;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.4rem 0.6rem;
  width: fit-content;
  background-color: rgb(255, 255, 255);
  border-radius: 20px 0 0 0;

  text-transform: uppercase;
}
.swiper-slide-hero-3-flex-detail h4 {
  color: #fff;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.9375rem;
  text-transform: capitalize;
  margin-top: 1.8rem;
}
.swiper-slide-hero-3-flex-project-detail {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 35.5rem;
  height: 100%;
  margin-top: 5rem;
  gap: 3rem 1rem;
}
.swiper-slide-hero-3-flex-project-detail h5 {
  color: #fff;
  font-family: "Poppins";
  font-size: 1rem;
  text-decoration-line: underline;
  font-weight: 600;
  text-transform: capitalize;
}
.swiper-slide-hero-3-flex-project-detail p {
  padding-top: 1rem;
  color: #fff;
  font-family: "Poppins";
  font-size: 0.9375rem;
  font-weight: 500;
  text-transform: capitalize;
}
.responsive-hero-section {
  display: none;
}

/* only side image responsive  */
@media (max-width: 1300px) {
  .swiper-slide-hero-fixed img {
    width: 26rem;
  }
  .swiper-slide-hero-fixed {
    position: absolute;
    top: 50px;
  }
}
@media (max-width: 1225px) {
  .swiper-slide-hero-2-sub p {
    width: 40rem;
  }
  .swiper-slide-hero-fixed {
    position: absolute;
    left: 700px;
  }
}
@media (max-width: 1024px) {
  .swiper-slide-hero-fixed img {
    width: 100%;
  }
  .swiper-slide-hero-fixed {
    left: 600px;
    top: 50px;
  }
  .swiper-slide-hero-3-flex-detail h4 {
    font-size: 1.7rem;
  }
  .swiper-slide-hero-3-flex-detail h5 {
    font-size: 1.2rem;
  }
  .swiper-slide-hero-3-flex-img img {
    width: 100%;
  }
  .swiper-slide-hero-3-flex-project-detail-1-hide {
    display: none;
  }
  .swiper-slide-hero-3-flex-project-detail {
    grid-template-columns: 1fr;
    margin-top: 2rem;
  }
  .swiper-slide-hero-3 {
    padding: 2rem 0;
  }
  .swiper-slide-hero-3-flex {
    gap: 0rem;
  }
}
@media (max-width: 920px) {
  .swiper-slide-hero-fixed {
    /* left: 600px; */
    top: 80px;
  }
}
@media (max-width: 880px) {
  .swiper-slide-hero-fixed {
    display: none;
  }
  .swiper-slide-hero-3-flex-img {
    display: none;
  }
  .swiper-slide-hero-2,
  .swiper-slide-hero-3 {
    display: none;
  }
}
@media (max-width: 1024px) {
  .swiper-slide-hero-1-sub h2 {
    width: 100%;
    font-size: 2.5rem;
  }
  .swiper {
    /* height: 85vh; */
  }
  .swiper-slide-hero-1-sub p {
    width: 100%;
    font-size: 0.9rem;
  }
  .swiper-slide-hero-1-sub a button {
    font-size: 0.85rem;
  }
}
@media (max-width: 768px) {
  .swiper-slide-hero-1-sub,
  .swiper-slide-hero-2-sub,
  .swiper-slide-hero-3-flex {
    align-items: center;
    text-align: center;
  }
  .swiper-slide-hero-3-flex-detail #swiper-slide-hero-3-flex-detail-h5 {
    align-self: center;
  }
  .swiper-slide-hero-3-flex-project-detail {
    width: 100%;
    align-self: center;
  }
  .swiper-slide-hero-1-sub h2 {
    font-size: 2rem;
    text-align: center;
  }
  .swiper-slide-hero-2-sub h2 {
    font-size: 2rem;
    width: 100%;
  }
  .swiper-slide-hero-1-sub p {
    font-size: 0.85rem;
    text-align: center;
  }
  .swiper-slide-hero-2-sub p {
    width: 100%;
    font-size: 0.85rem;
  }
  /* .hero-section {
    display: none;
  } */
  .responsive-hero-section {
    display: flex;
    margin-top: -102px;
    height: 80vh;
  }
}
@media (max-width: 535px) {
  .swiper-slide-hero-2-sub,
  .swiper-slide-hero-1-sub {
    margin-top: 3rem;
  }
}
@media (max-width: 550px) {
  .swiper-slide-hero-1-sub h2 {
    font-size: 1.3rem;
  }
  .swiper-slide-hero-1-sub h5 {
    font-size: 1.1rem;
  }
  .swiper-slide-hero-1-sub p {
    font-size: 0.8rem;
  }
}
@media (max-width: 450px) {
  .swiper-slide-hero-2-sub h2 {
    font-size: 1.3rem;
  }
  .swiper-slide-hero-2-sub h5 {
    font-size: 1.1rem;
  }
  .swiper-slide-hero-2-sub p {
    font-size: 0.8rem;
  }
  .swiper-slide-hero-1-sub h2 {
    font-size: 1.3rem;
  }
  .swiper-slide-hero-1-sub p {
    font-size: 0.8rem;
  }
  .swiper {
    height: 80vh;
  }
}
@media (max-width: 400px) {
  /* .swiper-slide-hero-3-flex-project-detail-1 {
    display: none;
  } */
  .swiper-slide-hero-3-flex-detail h4 {
    font-size: 1.7rem;
    line-height: 2.2rem;
  }
  .swiper-slide-hero-3-flex-detail h5 {
    font-size: 1rem;
  }
  .swiper-slide-hero-3-flex-detail p {
    font-size: 0.8rem;
  }
}

/* button  */
