.about-us {
  width: 100%;
}
.progress-bar-heading {
  margin: 6.25rem 0;
}
.progress-bar-heading h2 {
  width: 49rem;
}

.about-progress-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 15rem);
  gap: 1.5rem;
}
@media (max-width: 1024px) {
  .about-progress-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 14rem);
  }
  .about-progress-grid .svgfile {
    right: -13px;
    top: 100px;
    width: 50%;
    z-index: -1;
  }
}
@media (max-width: 800px) {
  .progress-bar-heading h2 {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .progress-bar-heading h2 {
    font-size: 2.5rem;
  }
}
@media (max-width: 650px) {
  .about-progress-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 12rem);
    gap: 1.5rem;
  }
  .about-progress-grid .svgfile {
    opacity: 0.6;
  }
}
@media (max-width: 425px) {
  .about-progress-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(7, 11rem);
    gap: 1.5rem;
  }
  .progress-bar-heading h2 {
    text-align: center;
  }
  .progress-bar-heading {
    margin: 4rem 0;
  }
}
.about-us-main-heading {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 10rem;
  margin-bottom: 2.5rem;
}
.about-us-main-heading h3 {
  width: 58rem;
  color: #000;
  font-size: 2.25rem;
  text-transform: lowercase;
}
.about-us-flex {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: -3rem;
}
@media (max-width: 1350px) {
  .about-us-flex {
    margin: 0;
  }
}
.about-us-flex .about-us-flex-detail {
  display: flex;
  flex-direction: column;
}
.about-us-flex-detail p {
  color: #000;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.875rem;
  text-transform: capitalize;
}
.about-us-flex-detail h4 {
  color: #000;
  font-family: "Poppins";
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: capitalize;
  padding: 2.5rem 0;
}
.about-us-flex-img {
  width: 90%;
}
.about-us-vision-mision {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  margin-top: 5rem;
}
.about-us-vision-mision-grid-1 {
  padding: 2rem 0;
}
.about-us-vision-mision-grid-1-sub {
  border-radius: 0.625rem;
  background: #f2f4ff;
  padding: 4rem 2.5rem;
  flex-direction: column;
  gap: 2.5rem;
}
.about-us-vision-misionh3 {
  color: #3c3c3c;
  text-align: center;
  font-size: 1.75rem;
  font-weight: 600;
  text-transform: uppercase;
}
.about-us-vision-mision p {
  color: #3c3c3c;
  text-align: center;
  font-family: "Poppins";
  font-weight: 500;
}
.about-us-vision-mision-grid-2 {
  flex-direction: column;
  border-radius: 0.625rem;
  background: linear-gradient(131deg, #a83b92 1.86%, #7f3da4 81.13%);
  padding: 4rem 2.5rem;
  flex-direction: column;
  gap: 2.5rem;
}
.about-us-vision-mision-grid-2 h3,
.about-us-vision-mision-grid-2 p {
  color: white;
}
@media (max-width: 1300px) {
  .about-us-flex-img img {
    width: 30.125rem;
  }
}
@media (max-width: 1024px) {
  .about-us-vision-mision-grid-1-sub {
    padding: 1.5rem 1rem;
  }
  .about-us-main-heading h3 {
    width: 100%;
    font-size: 2rem;
  }

  .about-us-main-heading {
    gap: 2rem;
  }
  .about-us-flex-detail p {
    font-size: 1rem;
  }
  .about-us-flex-img img {
    width: 28rem;
  }
  .about-us-vision-mision p {
    font-size: 400;
  }
  .about-us-vision-mision h3 {
    font-weight: 600;
  }
}
@media (max-width: 900px) {
  .about-us-flex-img img {
    width: 20rem;
  }
  .about-us-vision-mision {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  .about-us-vision-mision-grid-1-sub {
    padding: 4rem 2.5rem;
  }
}
@media (max-width: 768px) {
  .about-us-flex-img img {
    width: 25rem;
  }
  .about-us-flex {
    grid-template-columns: 1fr;
  }
  .about-us-vision-mision {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
  .about-us-main-heading h3 {
    font-size: 1.5rem;
  }
  .about-us-main-heading h2 {
    font-size: 2.5rem;
  }
}
@media (max-width: 425px) {
  .about-us-main-heading,
  .about-us-flex-detail {
    text-align: center;
  }

  .about-us-flex-img img {
    width: 20rem;
  }
}
@media (max-width: 350px) {
  .about-us-flex-img img {
    width: 100%;
  }
}
.about-image-scroll-container {
  margin: 5rem 0;
}
.about-image-scroll img,
.about-image-scroll-1 img {
  width: 100%;
}
.about-image-scroll-2 {
  margin-top: 2rem;
}
.about-image-scroll-1 {
  display: flex;
  animation: aboutimage 30s infinite linear forwards;
}
@keyframes aboutimage {
  from {
    /* transform: translateX(-7000px); */
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
.about-image-scroll-heading {
  margin: 3rem 0;
}
.about-image-scroll-heading h4 {
  color: #000;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.1875rem;
  text-transform: capitalize;
  text-align: center;
}
.about-us-testimonials {
  margin-top: 10rem;
}
@media (max-width: 1024px) {
  .about-image-scroll-heading h4 {
    font-size: 1.9rem;
  }
}
@media (max-width: 768px) {
  .about-image-scroll-heading h4 {
    font-size: 1.5rem;
  }
}
@media (max-width: 425px) {
  .about-image-scroll-heading h4 {
    font-size: 1.2rem;
  }
}

/* CONTACT US  */
.contact-us-form {
  margin: 10rem 0;
}
.contact-from-grid-details-contact-in-page .contact-from-grid {
  grid-template-columns: 1fr 1fr;
}
.contact-from-grid-details-contact-in-page .contact-change-contact-page {
  display: inline-block;
}
.contact-from-grid-details-contact-in-page .contact-from-grid-from {
  grid-column-start: -3;
  grid-column-end: -1;
}
/* .contact-from-grid-details-contact-in-page .contact-from-grid-from {
  max-width: 70%;
} */
.contact-from-grid-details-contact-in-page .contact-from-grid-from {
  width: 100%;
}
.contact-from-grid-details-contact-in-page
  .contact-from-grid-details-contact-3 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-from-grid-details-contact-in-page
  .contact-from-grid-details-contact-4 {
  display: flex;
}
@media (max-width: 768px) {
  .contact-from-grid-details-contact-in-page .contact-change-contact-page {
    display: none !important;
  }
  .contact-from-grid-details-contact-in-page .contact-from-grid {
    grid-template-columns: 1fr;
  }
}
/* SERVICES  */
.service-header {
  background: #df9926;
}

/* GALLERY  */
.gallery {
  width: 100%;
  position: relative;
  z-index: 1;
}
.gallery-main-heading {
  text-align: center;
  margin: 5rem 0;
}
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(3, 340px);
  gap: 20px;
}
.gallery-container {
  margin: 5rem 0 10rem 0;
}
.gallery-grid-1 {
  cursor: pointer;
  transition: all 0.5s;
  -webkit-tap-highlight-color: transparent;
}
.gallery-grid-1:hover {
  opacity: 0.8;
}
.gallery-grid-1 img {
  width: 340px;
  /* height: 255px; */
}
.popup-one-photo {
  display: none;
}
.popup-one-photo-open {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: #000000ed;
  z-index: 999;
  justify-content: center;
  align-items: center;
}
.popup-one-photo-img {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-one-photo img {
  width: 30%;
}
.popup-one-photo-button-open {
  display: none;
}
.popup-one-photo-button {
  display: inline-block;
  position: fixed;
  top: 24%;
  right: 31%;
  z-index: 9999;
}
.popup-one-photo-button button {
  all: unset;
  cursor: pointer;
}
.popup-one-photo-button button i {
  color: white;
}
@media (max-width: 1200px) {
  .gallery-grid-1 img {
    width: 240px;
    /* height: 255px; */
  }
  .gallery-grid {
    grid-template-columns: repeat(3, 240px);
  }
}
@media (max-width: 1000px) {
  .gallery-grid-1 img {
    width: 200px;
  }
  .gallery-grid {
    grid-template-columns: repeat(3, 200px);
  }
}
@media (max-width: 700px) {
  .gallery-grid {
    grid-template-columns: repeat(2, 200px);
  }
}
@media (max-width: 500px) {
  .gallery-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .gallery-grid-1 img {
    width: 100%;
  }
}

/* Product page  */
.product-main-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5rem;
  margin: 5rem 0;
}
.product-grid {
  position: relative;
  padding: 1rem 2rem;
  border-radius: 0.8125rem;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: baseline;
  z-index: 1;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
}
.product-grid-hover {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5.375rem;
  height: 5.0625rem;
  background: #e82f7012;
  z-index: -1;
  border-radius: 0.625rem 0;
  transition: all 0.3s;
}
.product-grid:hover .product-grid-hover {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.product-grid .product-grid-sub-1 h3 {
  color: #c9c9c9;
  font-size: 3.75rem;
  font-weight: 600;
  text-transform: uppercase;
}
.product-grid .product-grid-sub-2 h4 {
  color: #000;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}
.product-grid .product-grid-sub-2 p {
  font-family: "poppins";
  margin: 1rem 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #000;
  font-weight: 400;
  text-transform: capitalize;
}
.product-grid-scroll {
  margin: 1rem 0;
}
.single-product-detail {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  justify-content: center;
  align-items: center;
  margin: 10rem 0 5rem 0;
}
.single-product-detail-sub {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
.single-product-detail-sub h3 {
  color: #000;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
}
.single-product-detail-sub p {
  color: #000;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  line-height: 2.5rem;
  text-transform: capitalize;
}
.single-products-brands {
  margin-bottom: 5rem;
}
.single-products-brands img {
  width: 70%;
}
.single-product-detail-img {
  width: 100%;
}
.single-products-brands {
  max-width: 50%;
}
.single-products-brands-image-single {
  width: 100%;
}
@media (max-width: 768px) {
  .product-main-grid,
  .single-product-detail {
    grid-template-columns: 1fr;
  }
  .single-product-detail-sub {
    text-align: center;
  }
}
@media (max-width: 425px) {
  .product-grid {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    text-align: center;
  }
}

/* BRANDS */

.brand-grid {
  margin: 6rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5rem 2rem;
}
.same-brand-grid ul a li {
  list-style: none;
  padding: 0.5rem 0;
}
.same-brand-grid h3 {
  margin-bottom: 1rem;
  text-transform: uppercase;
}

@media (max-width: 1024px) {
  .brand-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 768px) {
  .brand-grid {
    grid-template-columns: 1fr;
  }
}
