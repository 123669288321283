.swiper-slide-hero-2-button {
  margin-top: 3.8rem;
  display: inline-block;
  background: transparent;
  padding: 8px 30px;
  border: 3px solid #fff;
  border-radius: 4px;
  transition: all 0.2s ease-in;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  background-color: transparent;
  /* color: #000; */
  z-index: 1;
  cursor: pointer;
}
.button-main a {
  -webkit-tap-highlight-color: transparent;
}
.swiper-slide-hero-2-button:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleY(1) scaleX(1.25);
  top: 100%;
  width: 140%;
  height: 180%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.swiper-slide-hero-2-button:after {
  content: "";
  position: absolute;
  left: 55%;
  transform: translateX(-50%) scaleY(1) scaleX(1.45);
  top: 180%;
  width: 160%;
  height: 190%;
  /* background-color: #4276e9; */
  /* background-color: #ffffff; */
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.swiper-slide-hero-2-button:hover {
  /* color: #ffffff; */
  color: #000000;
  /* border: 1px solid #4276e9; */
}

.swiper-slide-hero-2-button:hover:before {
  top: -35%;
  /* background-color: #4276e9; */
  background-color: white;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.swiper-slide-hero-2-button:hover:after {
  top: -45%;
  /* background-color: #4276e9; */
  background-color: white;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}
@media (max-width: 764px) {
  .swiper-slide-hero-2-button {
    padding: 7px 25px;
    font-size: 15px;
  }
}
.swiper-slide-hero-1-sub-button {
  margin: 0;
  padding: 10px 20px;
  font-size: 0.9rem;
}
.swiper-slide-hero-1-sub-button {
  color: #ffffff;
}
.swiper-slide-hero-1-sub-button:hover {
  color: #000000;
}
.swiper-slide-hero-1-sub-button:hover:before {
  /* background-color: #407bff; */
  background-color: white;
}

.swiper-slide-hero-1-sub-button:hover:after {
  /* background-color: #407bff; */
  background-color: white;
}
.swiper-slide-hero-1-sub-button:after {
  /* background-color: #407bff; */
  background-color: white;
}

/* Banner Button  */
.banner-button-sub {
  margin-top: 2rem;
  color: #ffffff !important;
  font: 16px;
  padding: 8px 30px;
}
.banner-button-sub:hover {
  color: #407bff !important;
}

.banner-button-sub:hover:before {
  background-color: #fff;
}

.banner-button-sub :hover:after {
  background-color: #fff;
}
.banner-button-sub:hover:after {
  background-color: #fff;
}
/* slide-3 button  */
.swiper-slide-hero-3-sub-button {
  margin-top: 0rem;
  color: #ffffff;
  font: 16px;
  padding: 8px 30px;
}
.swiper-slide-hero-3-sub-button:hover {
  color: #000000;
}

.swiper-slide-hero-3-sub-button:hover:before {
  background-color: #fff;
}

.swiper-slide-hero-3-sub-button :hover:after {
  background-color: #fff;
}
.swiper-slide-hero-3-sub-button:hover:after {
  background-color: #fff;
}
