@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: rgba(105, 105, 105, 0.39);
}
::-webkit-scrollbar-thumb {
  width: 6px;
  background: linear-gradient(131deg, #a83b92 1.86%, #7f3da4 81.13%);
  border-radius: 20px;
}

html {
  scroll-behavior: smooth;
}
body {
  /* overflow-x: hidden; */
  scroll-behavior: smooth;
}
.container {
  max-width: 133rem;
  padding: 0 6.25rem;
  margin: 0 auto;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
h2 {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 4rem;
  font-weight: 700;
  text-transform: uppercase;
}
h3 {
  color: #253559;
  font-family: "Montserrat", sans-serif;
  font-size: 1.25em;
  font-weight: 700;
}
a {
  text-decoration: none;
}
.p-mon {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 500;
}
.p-pop {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}
@media (max-width: 1024px) {
  .container {
    padding: 0 2.5rem;
  }
  h2 {
    font-size: 2.5rem;
  }
}
@media (max-width: 425px) {
  h2 {
    font-size: 1.8rem;
    text-align: center;
  }
  p {
    font-size: 0.875rem;
  }
}

/* fixed whatsapp icon  */
.main-app-container {
  position: relative;
}
.fixed-whatsapp-icon {
  position: fixed;
  top: 92.5%;
  right: 3%;
  z-index: 9999999999999999;
}
.fixed-whatsapp-icon-sub i {
  font-size: 3.5rem;
  color: #3fc250;
}
