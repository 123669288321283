.why-nxt {
  width: 100%;
}
.why-nxt-heading {
  flex-direction: column;
  gap: 1rem;
}
.why-nxt-grid {
  margin: 5rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4.375rem;
  align-items: baseline;
}
.why-nxt-grid-1 {
  flex-direction: column;
  gap: 1.5rem;
}
.why-nxt-grid-1 img {
  width: 4.75rem;
  transition: all 0.5s;
  height: auto;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.why-nxt-grid-1 img:hover {
  transform: scale(1.1);
}
.why-nxt-grid-1 h3 {
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
}
.why-nxt-grid-1 p {
  color: #000;
  text-align: center;
  font-weight: 500;
  line-height: 1.6875rem;
  text-transform: capitalize;
}
@media (max-width: 1024px) {
  .why-nxt-grid {
    gap: 2.5rem;
  }
  .why-nxt-grid-1 h3 {
    font-size: 1.1rem;
  }
  .why-nxt-grid-1 p {
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
  .why-nxt-grid-1 img {
    width: 4rem;
  }
}
@media (max-width: 768px) {
  .why-nxt-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .why-nxt-heading h2 {
    font-size: 2.5rem;
  }
}
@media (max-width: 425px) {
  .why-nxt-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .why-nxt-heading h2 {
    font-size: 2.2rem;
  }
}
/* BANNER  */
.banner {
  width: 100%;
  margin: 10rem 0;
  background: #407bff;
}
.banner-flex {
}
.banner-grid {
  max-width: 72rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 3.7rem 6.2rem;
  border-radius: 0rem 12.5rem 0rem 0rem;
}

.banner-grid-content {
}
.banner-grid-content h4 {
  color: #fff;
  font-size: 2.3rem;
  font-weight: 700;
  line-height: 3.1875rem;
  text-transform: capitalize;
}
.banner-grid-content p {
  color: #fff;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
  padding: 2rem 0;
}
.banner-grid-content.banner-button {
  display: flex;
}

.banner-grid-svg img {
  width: 75%;
}
@media (max-width: 1024px) {
  .banner-grid-svg img {
    width: 80%;
  }
  .banner-grid {
    padding: 3.7rem 3.2rem;
  }
  .banner-grid-content a {
    margin: 0;
  }
  .banner-grid-content a button {
    margin: 0;
  }
}
@media (max-width: 768px) {
  .banner-grid {
    grid-template-columns: 1fr;
    gap: 3rem;
    border-radius: 0rem 6.5rem 0rem 0rem;
  }
  .banner-grid-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .banner-grid-content p {
    font-size: 1rem;
  }
}
@media (max-width: 425px) {
  .banner-grid {
    padding: 1rem;
    border-radius: 0rem 2.5rem 0rem 0rem;
  }
  .banner-grid-content h4 {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
  .banner-grid-content p {
    font-size: 0.9rem;
  }
}
@media (max-width: 320px) {
  .banner-grid-svg {
    display: none;
  }
}

/* CONTACT FORM  */
.contact-form {
  width: 100%;
  margin: 5rem 0;
}
.contact-from-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}
.contact-from-grid-details-heading {
  flex-direction: column;
  align-items: baseline;
}
.contact-from-grid-details-heading h2 {
  width: 100%;
}
.contact-from-grid-details-heading p {
  color: #000;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 1.6rem 0;
  width: 85%;
}
.same-grid-contact-form {
  border-radius: 0.3125rem;
  background: #f5f5f5;
  padding: 1rem 1rem;
  flex-direction: column;
  gap: 0.7rem;
}
.contact-from-grid-details-contact-5 {
  align-items: flex-start;
}
.same-grid-contact-form h5 {
  color: #000;
  font-size: 1.25rem;
  font-weight: 600;
}
.same-grid-contact-form img {
}
.same-grid-contact-form a p {
  color: #000;
  font-weight: 600;
}
.contact-from-grid-details-contact-4,
.contact-from-grid-details-contact-3 {
  display: none;
}
.contact-from-grid-details-contact {
  margin: 3rem 0;
}
.contact-from-grid-details-contact a {
  -webkit-tap-highlight-color: transparent;
}
.contact-from-grid-details-contact-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
.contact-from-grid-details-contact-grid-1 {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
.contact-from-grid-details-contact-3 {
  align-items: flex-start;
}
.contact-from-grid-from {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.same-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.same-form label {
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
}
.same-form input {
  border: 1px solid #c9c9c9;
  background: #fff;
  height: 3rem;
  padding-left: 2rem;
}
.same-form label span {
  color: red;
}
.same-form textarea {
  border: 1px solid #c9c9c9;
  color: black;
  height: 10rem;
  padding-left: 2rem;
  font-size: 1rem;
  padding-top: 1rem;
}
.same-form input,
.same-form textarea::placeholder {
  /* color: #060606; */
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
}
.same-form input,
.same-form textarea:focus {
  /* border: 1px solid #000; */
  /* outline: 1px solid black; */
}

.contact-from-first-last-name {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
.contact-form-button {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
.contact-form-service-option select {
  border: 1px solid #c9c9c9;
  background: #fff;
  height: 3rem;
  padding-left: 2rem;
  width: 100%;
}
.contact-form-service-option select::placeholder {
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
}
.contact-form-button p {
  color: #000;
  font-weight: 500;
}
.contact-form-button button {
  all: unset;
  padding: 0.8rem 0;
  width: 10.5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  background: #407bff;
  text-align: center;
}
.contact-form-result-button {
  display: flex;
  gap: 2rem;
  justify-content: flex-start;
  align-items: center;
}
.contact-form-result-button p {
  transition: all 0.5s;
  color: #15ad08;
}
.contact-change-contact-page {
  display: none;
}
@media (max-width: 950px) {
  .contact-from-grid {
    grid-template-columns: 1fr;
  }
  .contact-from-grid-details-heading {
    flex-direction: column;
    justify-content: center;
  }
  .contact-from-grid-details-heading h2,
  .contact-from-grid-details-heading p {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .contact-from-grid-details-heading h2 {
    font-size: 2.5rem;
  }
  .contact-from-grid-details-heading p {
    font-size: 1rem;
  }
}
@media (max-width: 550px) {
  .contact-from-first-last-name {
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  }
}
@media (max-width: 450px) {
  .contact-from-grid-details-contact-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .same-grid-contact-form {
    padding: 1rem;
  }
  .contact-from-grid-details-heading h2 {
    font-size: 1.8rem;
    text-align: center;
  }
  .contact-from-grid-details-heading p {
    font-size: 0.9rem;
  }
}

/* PROGRESS  */

.progress {
  width: 100%;
  margin: 5rem 0;
}
.progress-heading {
}
.progress-heading h2 {
  width: 49rem;
}
.progress-heading p {
  width: 38rem;
  color: #515151;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
  padding-top: 3rem;
}
.progress-heading p span {
  color: #000;
  font-weight: 700;
}
.progress-main-grid {
  margin: 4rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
}
.progress-grid-1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 15rem);
  gap: 1.5rem;
}
.progress-grid-2 img {
  width: 100%;
}
.progress-process-heading {
  text-align: center;
  padding: 4rem 0;
}
.progress-same {
  position: relative;
  display: flex;
  align-items: self-end;
  flex-direction: column;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  background: #f2f0f0;
  z-index: 1;
  overflow: hidden;
}
.progress-same h2 {
  text-align: right;
}
.progress-same p {
  align-self: start;
  padding-top: 1rem;
}
.svgfile {
  position: absolute;
  right: -13px;
  top: 125px;
  width: 50%;
  text-align: right;
  z-index: -1;
}
@media (max-width: 1024px) {
  .progress-same h2 {
    font-size: 2.5rem;
  }
  .progress-grid-1 {
    grid-template-rows: repeat(4, 13rem);
  }
  .progress-heading h2 {
    width: 100%;
  }
  .progress-same p {
    font-size: 0.9rem;
  }
}
@media (max-width: 768px) {
  .progress-heading h2 {
    font-size: 2.5rem;
  }
  .progress-main-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .progress-grid-1 {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
  }

  .svgfile {
    top: 80px;
    opacity: 0.5;
  }
  .progress-heading p {
    width: 100%;
  }
  .progress-same {
  }
  .progress-same p {
  }
}
@media (max-width: 650px) {
  .progress-grid-1 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 425px) {
  .progress-same {
    padding: 0.8rem 1rem;
  }
  .progress-heading h2 {
    font-size: 2.2rem;
    text-align: center;
  }
}
@media (max-width: 375px) {
  .progress-grid-1 {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* WINDOWS  */

.windows {
  width: 100%;
  margin: 5rem 0;
  padding: 15rem 0;
  background: #2b142c;
  /* border-radius: 10rem 10rem 10rem 10rem; */
}
.window {
  /* background-color: aqua; */
}
.windows-grid {
  /* width: 63.125rem; */
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(6, 150px);
  grid-template-rows: repeat(4, 150px);
}
.windows-grid a {
  -webkit-tap-highlight-color: transparent;
}
.windows-grid-3 {
  grid-area: 1/3/2/5;
}
.windows-grid-4 {
  grid-area: 1/5/3/7;
}
.windows-grid-6 {
  grid-area: 2/2/3/4;
}
.windows-grid-8 {
  grid-area: 3/1/5/3;
}
.windows-grid-9 {
  grid-area: 3/3/4/5;
}
.windows-grid-1,
.windows-grid-3,
.windows-grid-4,
.windows-grid-6,
.windows-grid-8,
.windows-grid-9,
.windows-grid-11,
.windows-grid-13,
.windows-grid-14 {
  background: #fff;
  box-shadow: -17px 0px 99px 0px rgba(81, 81, 81, 0.25);
}
.windows-grid-1 {
  background: red;
  position: relative;
  transition: all 0.4s;
  z-index: 1;
  overflow: hidden;
}
.windows-grid-1-hide {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../Images/Windows/project.png");
  background-position: center;
  background-size: cover;
  transition: all 0.4s;
  z-index: -1;
  right: 0px;
  bottom: 0px;
}
.windows-grid-1:hover .windows-grid-1-hide {
  transform: rotate(360deg);
  right: 150px;
  bottom: 150px;
}
/* START  */

/* END  */

.windows-grid-1 h4 {
  color: white;
}

.windows-grid-3 {
  position: relative;
  background-image: url("../Images/Windows/BLOGWINDOW.png");
  background-position: center;
  background-size: cover;
  z-index: 1;
  overflow: hidden;
}
.windows-grid-3 h4 {
  color: black;
  transition: all 0.6s;
}
.windows-grid-3-hide {
  position: absolute;
  top: 100%;
  right: -100%;
  background: #2b142c;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  z-index: -1;
}
.windows-grid-3:hover .windows-grid-3-hide {
  transform: rotate(360deg);
  right: 0px;
  top: 0px;
}
.windows-grid-3:hover h4 {
  color: white;
}
.windows-grid-4 {
  background: #ebb376;
}
.windows-grid-4 img {
  width: 8.375rem;
}
.windows-grid-11 {
  position: relative;
  /* background-color: #000; */
  background-image: url("../Images/Windows/map.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 425px 425px;
  z-index: 1;
  overflow: hidden;
}
.windows-grid-11-hide,
.windows-grid-13-hide {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff29;
  transition: all 0.4s;
  right: 150px;
  bottom: -150px;
}
.windows-grid-11:hover .windows-grid-11-hide {
  transform: rotate(360deg);
  right: 0px;
  bottom: 0px;
}
.windows-grid-13 {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.windows-grid-13-hide {
  background: #ffc436;
  bottom: 150px;
}
.windows-grid-13:hover .windows-grid-13-hide {
  transform: rotate(360deg);
  right: 0px;
  bottom: 0px;
}
.windows-grid-6 {
  /* background: #df9926; */
  background-image: url("../Images/Windows/Services.png");
  background-position: center;
  background-size: cover;
  position: relative;
  transition: all 0.4s;
  z-index: 1;
  transition: all 0.4s;
  overflow: hidden;
}
.windows-grid-6-h4 h4 {
  color: #ffffff;
}
.windows-grid-6-h4 {
  transition: all 1s;
}
.windows-grid-6-hide {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: -1;
  left: 308px;
  bottom: 150px;
  transition: all 0.4s;
}
.windows-grid-6:hover .windows-grid-6-h4 h4 {
  color: #e7b202;
}
/* .windows-grid-6-h4:hover {
  color: #df9926;
} */
.windows-grid-6:hover .windows-grid-6-hide {
  transform: rotate(360deg);
  left: 0px;
  bottom: 0px;
}
.windows-grid-8 {
  position: relative;
  cursor: pointer;
  transition: all 0.8s;
  overflow: hidden;
}
.windows-grid-8-front {
  position: absolute;
  height: 100%;
  width: 100%;
  /* background: #274560; */
  background-image: url("../Images/Windows/About.png");
  background-position: center;
  background-size: cover;
  transition: all 0.8s;
  top: 0;
}
.windows-grid-8:hover .windows-grid-8-front {
  top: -308px;
}
.windows-grid-8-hide {
  height: 100%;
  position: absolute;
  padding: 2.2rem 1.5rem;
  text-align: center;
  flex-direction: column;
  background: #558498;
  transition: all 0.8s;
  top: 308px;
}
.windows-grid-8:hover .windows-grid-8-hide {
  top: 0px;
}
.windows-grid-9 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  /* transition: all 1s; */
}
.windows-grid-9-hide {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: -1;
  right: 326px;
  bottom: 69px;
  /* transform: rotate(26deg); */
  transition: all 0.4s;
  /* transform: scale(0); */
}
.windows-grid-9:hover .windows-grid-9-hide {
  /* transform: scale(2); */
  transform: rotate(26deg);
  right: 0px;
  bottom: -300px;
  width: 383px;
  height: 516px;
  border-radius: 20%;
}

.windows-grid-8-hide h3 {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
}
.windows-grid-8-hide p {
  color: #fff;
  font-family: "Poppins";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.575rem;
  text-transform: capitalize;
}
.windows-grid-8-hide hr {
  width: 3.5rem;
  margin: 1rem 0;
  border: 3px solid white;
  border-radius: 50%;
}
.windows-grid-8 h4 {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 700;
}
.windows-grid-14 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 0.2rem;
}
.windows-grid-14-1,
.windows-grid-14-2,
.windows-grid-14-3,
.windows-grid-14-4 {
  position: relative;
  z-index: 1;
  background: #3b5998;
  height: 100%;
  overflow: hidden;
}
.windows-grid-14-1-hide,
.windows-grid-14-2-hide,
.windows-grid-14-3-hide,
.windows-grid-14-4-hide {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #1977f3;
  z-index: -1;
  right: 75px;
  bottom: 75px;
  transition: all 0.4s;
}
.windows-grid-14-1:hover .windows-grid-14-1-hide {
  transform: rotate(360deg);
  right: 0px;
  bottom: 0px;
}
/* .windows-grid-14:hover .windows-grid-14-1 img {
  transform: scale(1.1);
} */
.windows-grid-14-2 {
  background: #15ad08;
}
.windows-grid-14-2-hide {
  background: #1d5e54;
  right: -75px;
  bottom: 75px;
}
.windows-grid-14-2:hover .windows-grid-14-2-hide {
  transform: rotate(360deg);
  right: 0px;
  bottom: 0px;
}
.windows-grid-14-3 {
  background: #bd3197;
  height: 100%;
}
.windows-grid-14-3-hide {
  background: #fba750;
  right: 75px;
  bottom: -75px;
}
.windows-grid-14-3:hover .windows-grid-14-3-hide {
  transform: rotate(360deg);
  right: 0px;
  bottom: 0px;
}
.windows-grid-14-4 {
  background: #00acee;
  height: 100%;
}

.windows-grid-14-4-hide {
  background: #000;
  right: -75px;
  bottom: -75px;
}
.windows-grid-14-4:hover .windows-grid-14-4-hide {
  transform: rotate(360deg);
  right: 0px;
  bottom: 0px;
}
@media (max-width: 1150px) {
  .windows-grid {
    grid-template-columns: repeat(6, 110px);
    grid-template-rows: repeat(5, 110px);
  }
}
@media (max-width: 1024px) {
  .windows-grid-4 img {
    width: 6rem;
  }
  .windows-grid-8-front h4,
  .windows-grid-6 h4,
  .windows-grid-3 h4 {
    font-size: 0.85rem;
    font-weight: 600;
  }
  .windows-grid-1 h4 {
    font-size: 0.7rem;
  }
  .windows-grid-8-hide h3 {
    font-size: 1rem;
    font-weight: 600;
  }
  .windows-grid-8-hide p {
    font-family: "Poppins";
    font-size: 0.68rem;
    font-weight: 400;
    line-height: 1.1rem;
  }
  .windows-grid-8-hide hr {
    border: 2px solid white;
  }
  .windows-grid-9 img {
    width: 8.5rem;
  }
  .windows-grid-11 img {
    width: 2.2rem;
  }
  .windows-grid-13 img {
    width: 2.2rem;
  }
  .windows-grid-14 img {
    width: 1.3rem;
  }
}
@media (max-width: 764px) {
  .windows-grid {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, 110px);
  }
}
@media (max-width: 700px) {
  .windows {
    display: none;
  }
}

/* NXT SKILLS POWER  */
.nxt-skills-power {
  position: relative;
  padding: 5rem 0;
  background: #0d0f11;
  width: 100%;
  color: white !important;
  z-index: 1;
}
.nxt-skills-power-heading {
  z-index: 1;
  padding: 0 2.5rem;
}
.nxt-power-background-line {
  position: absolute;
  right: 0px;
  left: 0px;
  z-index: -1;
}
.nxt-power-background-line img {
  width: 100%;
}
.nxt-skills-power-heading h2 {
  color: #404243;
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
  text-transform: capitalize;
}
.nxt-skills-power-main-grid {
  gap: 3rem;
  justify-content: space-around;
  position: relative;
  z-index: 1;
}
.nxt-skills-power-ball {
  position: absolute;
  height: 8rem;
  width: 8rem;
  background: radial-gradient(50% 50% at 50% 50%, #fefefe 0%, #424242 100%);
  border-radius: 50%;
  top: 0px;
  right: -450px;
  transition: all 0.8s;
  z-index: -1;
  animation: bounce 0.5s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-iteration-count: infinite;
}
.nxt-skills-power-ball-1-1 {
  position: absolute;
  height: 8rem;
  width: 8rem;
  background: radial-gradient(50% 50% at 50% 50%, #fefefe 0%, #424242 100%);
  border-radius: 50%;
  top: 0px;
  right: -450px;
  transition: all 0.8s;
  z-index: -1;
  animation: bounce 0.5s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-iteration-count: infinite;
}
@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 200px, 0);
  }
}
.same-next-power-ball {
  position: absolute;
  height: 1rem;
  width: 1rem;
  top: 0px;
  right: 0px;
  background: radial-gradient(50% 50% at 50% 50%, #fefefe 0%, #424242 100%);
  border-radius: 50%;
  animation: bounce 0.7s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-iteration-count: infinite;
  z-index: -1;
}
.nxt-skills-power-ball-2 {
  top: 0px;
  right: 600px;
}
.nxt-skills-power-sub-gird {
  display: grid;
  grid-template-columns: repeat(3, 168px);
  grid-template-rows: repeat(10, 55px);
  justify-content: left;
  margin: 6rem 0;
}
.power-same-grid {
  background-size: cover;
  background-position: center;
  transition: all 0.5s;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.power-same-grid p {
  text-align: center;
  font-size: 0.8125rem;
  font-weight: 700;
  text-transform: capitalize;
  padding: 0 2.5rem;
  margin-top: -3rem;
}
.power-sub-gird-2 {
  grid-area: 1 / 2/ 5 / 3;
  background-image: url("../Images/NXT\ Power/hex.svg");
}
.power-sub-gird-8 {
  grid-area: 4 / 1/ 8 / 2;
  background-image: url("../Images/NXT\ Power/hex.svg");
}
.power-sub-gird-9 {
  grid-area: 4 / 3/ 8 / 4;
  background-image: url("../Images/NXT\ Power/hex.svg");
}
.power-sub-gird-12 {
  grid-area: 7 / 2/ 11 / 3;
  background-image: url("../Images/NXT\ Power/hex.svg");
}
.power-sub-gird-2-ball {
  top: 116px;
  right: 188px;
  filter: drop-shadow(0px 0px 50px rgba(231, 46, 111, 0.5));
  background: radial-gradient(50% 50% at 50% 50%, #fefefe 0%, #e72e6f 100%);
}
.power-sub-gird-8-ball {
  top: 283px;
  right: 356px;
  filter: drop-shadow(0px 0px 50px rgba(231, 46, 111, 0.5));
  background: radial-gradient(50% 50% at 50% 50%, #fefefe 0%, #e72e6f 100%);
}
.power-sub-gird-9-ball {
  top: 283px;
  right: 20px;
  filter: drop-shadow(0px 0px 50px rgba(231, 46, 111, 0.5));
  background: radial-gradient(50% 50% at 50% 50%, #fefefe 0%, #e72e6f 100%);
}
.power-sub-gird-12-ball {
  top: 448px;
  right: 190px;
  filter: drop-shadow(0px 0px 50px rgba(231, 46, 111, 0.5));
  background: radial-gradient(50% 50% at 50% 50%, #fefefe 0%, #e72e6f 100%);
}
.power-sub-gird-12:hover {
  transform: scale(0.9);
}
.power-sub-gird-9:hover {
  transform: scale(0.9);
}
.power-sub-gird-8:hover {
  transform: scale(0.9);
}
.power-sub-gird-2:hover {
  transform: scale(0.9);
}
.nxt-skills-power-gird-2 {
  width: 31rem;
  margin-top: 180px;
}
.nxt-skills-power-gird-2-detail h4 {
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: capitalize;
}
.nxt-skills-power-gird-2-detail h3 {
  color: white;
  padding: 2.5rem 0;
  font-size: 1.875rem;
  font-weight: 600;
  text-transform: capitalize;
}
.nxt-skills-power-gird-2-detail p {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.875rem;
  text-transform: capitalize;
}
.nxt-power-hidden-text-1-main {
  padding: 3rem 0 0 0;
}
.nxt-power-hidden-text-same {
  display: none;
}
.nxt-power-hidden-text-1-p {
  color: #b6b6b6;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2.0625rem;
  text-transform: capitalize;
}
.nxt-power-hidden-text-1-p span {
  color: white;
  font-weight: 800 !important;
}
.nxt-power-hidden-text-1 {
  display: flex;
}
.nxt-power-hidden-text-1-hide {
  display: none;
}
.nxt-power-hidden-text-2,
.nxt-power-hidden-text-3,
.nxt-power-hidden-text-4 {
  display: none;
}

.nxt-power-hidden-text-2-hide,
.nxt-power-hidden-text-4-hide,
.nxt-power-hidden-text-3-hide {
  display: flex;
}
@media (max-width: 1100px) {
  .nxt-skills-power-sub-gird {
    grid-template-columns: repeat(3, 136.1px);
    grid-template-rows: repeat(10, 45px);
  }
  .power-same-grid p {
    font-size: 0.7rem;
  }
  .nxt-skills-power-gird-2-detail h3 {
    font-size: 1.5rem;
    padding: 1.8rem 0;
  }
  .nxt-skills-power-gird-2-detail h4 {
    font-size: 1.1rem;
  }
  .nxt-skills-power-gird-2-detail p {
    font-size: 0.85rem;
  }
  .nxt-skills-power-gird-2 {
    width: 100%;
    margin-top: 5.625rem;
  }
  .nxt-power-hidden-text-1-main {
    padding: 2rem 0 0 0;
  }
  .nxt-skills-power-heading h2 {
    font-size: 2.5rem;
  }
  .nxt-skills-power-ball {
    right: -400px;
    width: 6.125rem;
    height: 6.125rem;
  }
  .power-sub-gird-2-ball {
    top: 120px;
    right: 155px;
  }
  .power-sub-gird-8-ball {
    top: 250px;
    right: 290px;
  }
  .power-sub-gird-9-ball {
    top: 256px;
    right: 20px;
  }
  .power-sub-gird-12-ball {
    top: 388px;
    right: 155px;
  }
  @keyframes bounce {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(0, 150px, 0);
    }
  }
}
/* (Responsive power) */
.nxt-power-responsive-in-power {
  display: none;
}
.nxt-power-responsive {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.5rem;
}
.nxt-power-responsive-grid {
  flex-direction: column;
  border-radius: 0.4375rem;
  background: #1e1e1e;
  box-shadow: 8px 8px 0px 0px #000;
  padding: 1.5rem 1rem;
  transition: all 0.5s;
}
.nxt-power-responsive-grid h4 {
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: capitalize;
}
.nxt-power-responsive-grid hr {
  background: #fff;
  height: 4px;
  width: 30%;
  border-radius: 40%;
  margin: 1.3rem 0;
}
.nxt-power-responsive-grid p {
  color: #b6b6b6;
  text-align: center;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-transform: capitalize;
}
.nxt-power-responsive-grid:hover {
  box-shadow: none;
}

@media (max-width: 900px) {
  .nxt-skills-power-main-grid {
    gap: 0;
    flex-direction: column;
  }
  .nxt-skills-power-ball {
    display: none;
  }
  .nxt-skills-power-sub-gird {
    margin-bottom: 0;
  }
  .nxt-skills-power-gird-2 {
    display: flex;
    margin-top: 2.5rem;
    text-align: center;
  }
  .nxt-skills-power-sub-gird {
    margin-top: 3rem;
  }
}
.nxt-skills-power-gird-1 {
  position: relative;
}
@media (max-width: 500px) {
  .nxt-power-responsive-in-power {
    display: inline-block;
  }
  .nxt-skills-power-main-grid {
    gap: 3rem;
  }
  .nxt-skills-power-gird-1 {
    display: none;
  }
  .nxt-power-hidden-text-1-main {
    display: none;
  }
  .nxt-skills-power-heading h2 {
    font-size: 1.5rem;
  }
  .nxt-skills-power-gird-2-detail h3 {
    font-size: 1.25rem;
  }
  .nxt-skills-power-gird-2-detail h4 {
    font-size: 1rem;
  }
}
/* NXT POWER TEXT SCROLL  */
.nxt-power-text-scroll-p {
  /* margin-top: 2rem; */
}
.nxt-power-text-scroll-p P {
  /* text-shadow: 0px 0px 7px rgba(160, 53, 156, 0.34); */
  color: #1e1e1e !important;
}

/* OUR CLIENT  */
.our-client {
  width: 100%;
  padding-bottom: 7rem;
  background: #0d0f11;
  color: white !important;
}
.our-client-heading h4 {
  font-size: 2.5rem;
  font-weight: 600;
  text-transform: uppercase;
}
.our-client-main-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.our-client-description {
}
.our-client-description h3 {
  color: white;
  width: 648px;
  font-size: 2rem;
  font-weight: 500;
  padding: 2.25rem 0;
}
.our-client-description p {
  width: 30rem;
  color: #fff;
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.1875rem;
  text-transform: capitalize;
}
.our-client-description-hidden-text-main {
  padding-top: 2.25rem;
}
.our-client-description-hidden-text-main p {
  font-family: "Poppins";
  font-size: 1rem;
  font-weight: 500;
  line-height: 2.1875rem;
  text-transform: capitalize;
}
.our-client-description-hidden-text-main p span {
  font-weight: 700;
}

.our-client-grid-main {
  display: grid;
  grid-template-columns: 81px 81px 81px 81px 81px 81px;
  grid-template-rows: repeat(3, 162px);
}
.our-client-grid-same {
  background-size: 162px 162px;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.5s;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.our-client-grid-same p {
  padding: 0 1rem;
  text-align: center;
  font-size: 0.8125rem;
  font-weight: 600;
  text-transform: capitalize;
}
.our-client-grid-6 p {
  font-size: 1.25rem;
  font-weight: 700;
}
.our-client-grid-2 {
  grid-area: 1 / 2 / 2 / 4;
  margin-right: 18px;
  background-image: url("../Images/NXT\ Power/HEX-black-white.svg");
}
.our-client-grid-3 {
  grid-area: 1 / 4 / 2 /6;
  margin-left: 18px;
  background-image: url("../Images/NXT\ Power/HEX-black-white.svg");
}
.our-client-grid-5 {
  grid-area: 2 / 1 / 3 / 3;
  margin-right: 20px;
  background-image: url("../Images/NXT\ Power/HEX-black-white.svg");
}
.our-client-grid-6 {
  grid-area: 2 / 3/ 3 / 5;
  margin-left: 7px;
  margin-right: 7px;
  background-image: url("../Images//NXT\ Power/HEX-color.svg");
}
.our-client-grid-7 {
  grid-area: 2 / 5/ 3 / 7;
  margin-left: 20px;
  background-image: url("../Images//NXT\ Power/HEX-color.svg");
}
.our-client-grid-9 {
  grid-area: 3 / 2/ 4 / 4;
  margin-right: 15px;
  background-image: url("../Images/NXT\ Power/HEX-black-white.svg");
}
.our-client-grid-10 {
  grid-area: 3 / 4/ 4 / 6;
  margin-left: 15px;
  background-image: url("../Images//NXT\ Power/HEX-color.svg");
}
.our-client-grid-2:hover {
  transform: scale(0.9);
}
.our-client-grid-3:hover {
  transform: scale(0.9);
}
.our-client-grid-5:hover {
  transform: scale(0.9);
}
.our-client-grid-6:hover {
  transform: scale(0.9);
}
.our-client-grid-7:hover {
  transform: scale(0.9);
}
.our-client-grid-9:hover {
  transform: scale(0.9);
}
.our-client-grid-10:hover {
  transform: scale(0.9);
}
.our-client-description-hidden-text-2,
.our-client-description-hidden-text-3,
.our-client-description-hidden-text-4,
.our-client-description-hidden-text-5,
.our-client-description-hidden-text-6 {
  display: none;
}
.our-client-hidden-text-1 {
  display: none;
}
.our-client-hidden-text-3 {
  display: inline-block;
}
/* .our-client-hidden-text-3 p {
  color: #a2379a;
} */
/* OUR CLIENT RESPONSIVE   */
.our-client-responsive {
  display: grid;
  grid-template-columns: repeat(1, 13.875rem);
  gap: 2.5rem;
}
.our-client-responsive-grid {
  padding: 1.2rem 0.8rem 2.5rem 0.8rem;
  border-radius: 0.5rem;
  background: #1e1e1e;
  box-shadow: 10px 10px 0px 0px #000;
  flex-direction: column;
}
.our-client-responsive-grid h4 {
  font-family: "Poppins";
  font-size: 0.9375rem;
  font-weight: 700;
  text-transform: capitalize;
}
.our-client-responsive-grid h4 span {
  color: #2d2d2d;
  font-size: 4rem;
  font-weight: 600;
}
.our-client-responsive-grid p {
  color: #b0b0b0;
  text-align: center;
  font-family: "Poppins";
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.4375rem;
  text-transform: capitalize;
}
.our-client-responsive-main {
  display: none;
}
@media (max-width: 1333px) {
  .our-client-description h3 {
    width: 100%;
  }
}
@media (max-width: 1170px) {
  .our-client-description p {
    width: 100%;
  }
  .our-client-grid-main {
    grid-template-columns: repeat(6, 70px);
    grid-template-rows: repeat(3, 140px);
  }
  .our-client-grid-same {
    background-size: 140px 140px;
  }
  .our-client-grid-same p {
    font-size: 0.7125rem;
  }
  .our-client-grid-6 p {
    font-size: 1rem;
    font-weight: 700;
  }
}

@media (max-width: 1024px) {
  .our-client-main-flex {
    gap: 2rem;
  }
  .our-client-description p {
    width: 100%;
  }
  .our-client-grid-main {
    grid-template-columns: repeat(6, 65px);
    grid-template-rows: repeat(3, 130px);
  }
  .our-client-grid-same {
    background-size: 130px 130px;
  }
  .our-client-description h3 {
    font-size: 1.6rem;
  }
  .our-client-description p {
    font-size: 0.9rem;
  }
}
@media (max-width: 900px) {
  .our-client-main-flex {
    gap: 1rem;
  }
  .our-client-grid-main {
    grid-template-columns: repeat(6, 60px);
    grid-template-rows: repeat(3, 120px);
  }
  .our-client-grid-same {
    background-size: 120px 120px;
  }
  .our-client-description h3 {
    font-size: 1.5rem;
  }
  .our-client-description p {
    font-size: 0.8rem;
  }
}
@media (max-width: 800px) {
  .our-client {
    padding: 0rem 0 7rem;
  }
  .our-client-main-flex {
    gap: 4rem;
    flex-direction: column;
    text-align: center;
  }
  .our-client-heading h4 {
    font-size: 2.5rem;
    text-align: center;
  }
}
@media (max-width: 500px) {
  .our-client-main-flex {
    gap: 2rem;
  }
  .our-client-grid-main {
    display: none;
  }
  .our-client-description-hidden-text-main {
    display: none;
  }
  .our-client-responsive-main {
    display: inline-block;
  }
  .our-client-heading h4 {
    font-size: 1.5rem;
  }
  .our-client-description h3 {
    font-size: 1.2rem;
  }
}
@media (max-width: 300px) {
  .our-client-responsive {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* SERVICES HOME  */
.services-home-section {
  width: 100%;
  margin: 5rem 0;
}
.services-home-section-main-grid {
  display: grid;
  gap: 1rem 2.5rem;

  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(5, 22.25rem); */
}
.services-home-section-heading {
  text-align: center;
  margin: 2rem 0;
}
.services-home-section-same-sub {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.services-home-section-same h3 {
  text-align: center;
}
.services-home-section-same a {
  -webkit-tap-highlight-color: transparent;
}
.services-home-section-same p {
  padding: 2rem 0.8rem 0 0.8rem;
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.875rem;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .services-home-section-same p {
    font-size: 0.8rem;
    padding: 1.5rem 0rem 0 0rem;
  }
  .services-home-section-same h3 {
    font-size: 1.1rem;
  }
  .services-home-section-main-grid {
    gap: 1rem 2rem;
  }
}
@media (max-width: 768px) {
  .services-home-section-main-grid {
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-rows: repeat(5, 1fr); */
  }
  .services-home-section-grid-15 {
    display: none;
  }
}
@media (max-width: 600px) {
  .services-home-section-main-grid {
    grid-template-columns: repeat(1, 1fr);
    /* grid-template-rows: repeat(5, 1fr); */
  }
}
